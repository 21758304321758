import ColumnTable from "./column-table";
var GlobalModule = /** @class */ (function () {
    function GlobalModule() {
    }
    GlobalModule.prototype.autoInit = function () {
        document.querySelectorAll('[data-columntable]').forEach(function (el) {
            new ColumnTable(el);
        });
    };
    GlobalModule.prototype.initElement = function (el) {
        new ColumnTable(el);
    };
    return GlobalModule;
}());
export default GlobalModule;

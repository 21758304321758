import DataParser from "./data-parser";
import TableCreator from "./table-creator";
var axios = require('axios').default;
var ColumnTable = /** @class */ (function () {
    function ColumnTable(el, data) {
        if (data === void 0) { data = null; }
        this.element = el;
        this.element.innerHTML = '';
        this.loadData(data);
    }
    ColumnTable.prototype.loadData = function (data) {
        var _this = this;
        if (data === void 0) { data = null; }
        if (data) {
            this.parser = new DataParser(data);
            this.init();
            return;
        }
        axios.get(this.element.dataset.columntable)
            .then(function (response) {
            _this.parser = new DataParser(response.data);
            _this.init();
        });
    };
    ColumnTable.prototype.init = function () {
        var _this = this;
        var table = new TableCreator(['Name', 'Sortable', 'Filterable', 'Editable', 'Exportable']);
        this.parser.getGroups().forEach(function (g) {
            table.writeSectionRow(g);
            var columns = _this.parser.findByGroup(g);
            columns.sort(function (a, b) {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
            columns.forEach(function (d) {
                table.writeDataRow([
                    d.name,
                    d.sorting ? '<span class="yes">Sort</span>' : '',
                    d.smart_filtering ? '<span class="yes">Smart Filter</span>' : '',
                    d.editing ? '<span class="yes">Editable</span>' : '',
                    d.export ? '<span class="yes">Export</span>' : '',
                ]);
            });
        });
        this.element.appendChild(table.getElement());
    };
    return ColumnTable;
}());
export default ColumnTable;

var DataParser = /** @class */ (function () {
    function DataParser(data) {
        this.data = data;
    }
    DataParser.prototype.findByGroup = function (type) {
        return this.data.filter(function (d) {
            return d.group === type;
        });
    };
    DataParser.prototype.getGroups = function () {
        var groups = [];
        this.data.forEach(function (d) {
            if (!groups.includes(d.group)) {
                groups.push(d.group);
            }
        });
        return groups;
    };
    return DataParser;
}());
export default DataParser;

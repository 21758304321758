var TableCreator = /** @class */ (function () {
    function TableCreator(headers) {
        this.element = document.createElement('table');
        this.element.classList.add('actable');
        this.headers = headers;
        this.writeHeaders();
        this.writeTbody();
    }
    TableCreator.prototype.getElement = function () {
        return this.element;
    };
    TableCreator.prototype.writeSectionRow = function (content) {
        var row = document.createElement('tr');
        var cell = document.createElement('td');
        cell.colSpan = this.headers.length;
        row.classList.add('section');
        cell.innerHTML = content;
        row.appendChild(cell);
        this.getBodySection().appendChild(row);
    };
    TableCreator.prototype.writeDataRow = function (data) {
        var _this = this;
        var row = document.createElement('tr');
        data.forEach(function (cellData) {
            row.appendChild(_this.createTableCellWithData(cellData));
        });
        this.getBodySection().appendChild(row);
    };
    TableCreator.prototype.createTableCellWithData = function (data) {
        var cell = document.createElement('td');
        cell.innerHTML = data;
        return cell;
    };
    TableCreator.prototype.getBodySection = function () {
        return this.element.querySelector('tbody');
    };
    TableCreator.prototype.writeTbody = function () {
        var bodySection = document.createElement('tbody');
        this.element.appendChild(bodySection);
    };
    TableCreator.prototype.writeHeaders = function () {
        var headerSection = document.createElement('thead');
        this.headers.forEach(function (header) {
            var headerElement = document.createElement('th');
            headerElement.innerHTML = header;
            headerSection.appendChild(headerElement);
        });
        this.element.appendChild(headerSection);
    };
    return TableCreator;
}());
export default TableCreator;
